<template>
    <v-chip-group v-model="value" mandatory active-class="accent--text">
        <v-chip
            v-for="step in steps"
            :key="step"
            :value="step"
            class="ma-2"
            color="primary lighten1"
            text-color="white"
            :close="step == steps && step != 1 ? true : false"
            @click="emitStep(step)"
            @click:close="removeStep(step)"
        >
            <v-avatar left>
                <v-icon size="20" class="mx-4">fa-solid fa-shoe-prints</v-icon>
            </v-avatar>
            {{ $t(`builder.components.step`) }} {{ step }}
        </v-chip>
    </v-chip-group>
</template>

<script>
export default {
    name: "StepChips",

    props: {
        steps: {
            required: true,
            type: Number,
        },
    },

    data() {
        return {
            value: null,
        };
    },

    methods: {
        emitStep(step) {
            this.$emit("update:stepper", step);
        },

        removeStep(step) {
            this.$emit("remove-step", step);
        },
    },

    watch: {
        steps(val) {
            this.value = val;
            if (val) this.$emit("update:stepper", val);
        },
    },
};
</script>
